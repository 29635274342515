export default {
    phone:[
        { required:true , message:"手机号不能为空", trigger: 'blur' },
        { pattern:/^(?:(?:\+|00)86)?1\d{10}$/, message:"手机号格式不正确", trigger: 'change' },
    ],
    mobile:[
        { required:true , message:"手机号不能为空", trigger: 'blur' },
        { pattern:/^(?:(?:\+|00)86)?1\d{10}$/, message:"手机号格式不正确", trigger: 'change' },
    ],
    password:[
        { required:true , message:"密码不能为空", trigger: 'blur' },
        { pattern: /^.{6,20}$/, message:"密码为6到20位", trigger: 'change' },
        { pattern: /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/, message:"密码强度太低", trigger: 'change' },
    ],
    code:[
        { required:true , message:"验证码不能为空", trigger: 'blur' },
        { pattern: /^\d{6}$/, message:"验证码为6位数字", trigger: 'blur' },
    ],
    id:[
        { required:true ,pattern:/^\d+$/,  message:"租户不能为空", trigger: 'blur' },
    ],
    businessUrl:[
        { required:true , message:"营业执照不能为空", trigger: 'blur' },
    ],
    creditCode:[
        { required:true , message:"统一社会信用代码不能为空", trigger: 'blur' },
        { pattern:/[a-zA-Z0-9]{18}/ , message:"统一社会信用代码为18位数字或英文字符组成", trigger: 'blur' },
    ],
    detail:[
        { required:true , message:"企业所在地不能为空", trigger: 'blur' },
    ],
    district:[
        { required:true , message:"地区不能为空", trigger: 'blur' },
    ],
    contacts:[
        { required:true , message:"前夕人不能为空", trigger: 'blur' },
    ],
}