import axios from "axios";
import { ElMessage } from 'element-plus';

const request = axios.create({
    baseURL: process.env.VUE_APP_AJAX_auth
})

// request.interceptors.request.use(
//     function (config) {
        
//         return config;
//     }, 
//     function (error) {
//         // return Promise.reject(error);
//     }
// );

request.interceptors.response.use(
    function (  { data }  ) {
        return data
    }, 
    function (error) {
        if(error.response.status==401){
            ElMessage.error({
                message:'请先登录'
            })
        } else if (error.response.status==403){
            ElMessage.error({
                message:'没有权限'
            })
        } else {
            error.response.data.messages.forEach( msg => {
                ElMessage.error({
                    message:msg
                })
            });
        }
        return Promise.reject(error);
    }
);

export default request