const routes = [
  {
    path: '/',
    component: () => import('@/views/HomeView.vue'),
    children: [
      {
        path: '/',
        redirect: '/home',
      },
      // topnav
      {
        path: 'home',
        name: 'homePage',
        component: () => import('@/views/pages/HomePage.vue'),
      },
      {
        path: 'product',
        name: 'product',
        component: () => import('@/views/pages/ProductsPage.vue'),
      },
      {
        path: 'technology',
        name: 'technology',
        component: () => import('@/views/pages/TechnologyPage.vue'),
      },
      {
        path: 'personal',
        name: 'personal',
        component: () => import('@/views/pages/PersonalPage.vue'),
      },
      {
        path: 'success',
        name: 'success',
        component: () => import('@/views/pages/SuccessPage.vue'),
      },
      {
        path: 'more',
        name: 'more',
        component: () => import('@/views/pages/MorePage.vue'),
      },
      // bottomnav
      {
        path: 'help',
        name: 'help',
        component: () => import('@/views/pages/BottomView/HelpPage.vue'),
      },
      {
        path: 'information',
        name: 'information',
        component: () => import('@/views/pages/BottomView/InformationPage.vue'),
        children: [
          {
            path: '/',
            redirect: '/information/history',
          },
          {
            path: 'history',
            name: 'history',
            component: () =>
              import(
                '@/views/pages/BottomView/informationView/HistoryPage.vue'
              ),
          },
          {
            path: 'result',
            name: 'result',
            component: () =>
              import('@/views/pages/BottomView/informationView/ResultPage.vue'),
          },
          {
            path: 'team',
            name: 'team',
            component: () =>
              import('@/views/pages/BottomView/informationView/TeamPage.vue'),
          },
          {
            path: '/:error(.*)',
            redirect: '/information/history',
          },
        ],
      },
      {
        path: 'contact',
        name: 'contact',
        component: () => import('@/views/pages/BottomView/ContactPage.vue'),
      },
      {
        path: 'customer',
        name: 'customer',
        component: () => import('@/views/pages/BottomView/CustomerPage.vue'),
      },
      {
        path: 'privacy',
        name: 'privacy',
        component: () => import('@/views/pages/BottomView/PrivacyPage.vue'),
      },
      {
        path: 'suggestion',
        name: 'suggestion',
        component: () => import('@/views/pages/BottomView/SuggestionPage.vue'),
      },
      // productsViews
      {
        path: 'base',
        name: 'base',
        component: () => import('@/views/pages/productsView/BasePage.vue'),
      },
      {
        path: 'robot',
        name: 'robot',
        component: () => import('@/views/pages/productsView/RobotPage.vue'),
      },
      {
        path: 'air',
        name: 'air',
        component: () => import('@/views/pages/productsView/AirPage.vue'),
      },
      {
        path: 'carbon',
        name: 'carbon',
        component: () => import('@/views/pages/productsView/carbon.vue'),
      },
      {
        path: 'open',
        name: 'open',
        component: () => import('@/views/pages/productsView/OpenPage.vue'),
      },
      {
        path: 'gateway',
        name: 'gateway',
        component: () => import('@/views/pages/productsView/GatewayPage.vue'),
      },
      {
        path: 'optical',
        name: 'optical',
        component: () => import('@/views/pages/productsView/OpticalPage.vue'),
      },
      // error
      {
        path: '/:error(.*)',
        redirect: '/home',
      },
    ],
  },
  //login
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
  //account
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/pages/AccountPage.vue'),
    children: [
      {
        path: '/',
        redirect: '/account/register',
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/pages/AccountView/RegisterPage.vue'),
      },
      {
        path: 'reset',
        name: 'reset',
        component: () => import('@/views/pages/AccountView/ResetPage.vue'),
      },
      // error
      {
        path: '/:error(.*)',
        redirect: '/home',
      },
    ],
  },
  {
    path: '/:error(.*)',
    redirect: '/home',
  },
]

export default routes