<template>
  <router-view />
</template>

<script>

export default {
    name:'AppPage',
}
</script>

<style lang="less">

</style>
