import request from "./util.js";
//获取二维码
const getQrCode = function(){
    return request.get("qrLogin/qrCode")
}
//登录
const singIn = function(params){
    return request.post("signIn",params)
}
//获取验证码
const getCode = function(params){
    return request.post("smsCode",{ mobile:params })
}
//校验验证码checkSmsCode
const checkSmsCode = function(params){
    return request.post("checkSmsCode",params)
}
//获取租户下拉列表
const getAllTenant = function(){
    return request.get("tenants/allTenant")
}
//注册
const signUp = function(params){
    return request.post("signUp",params)
}
//企业注册
const businessSignUp = function(params){
    return request.post("businessSignUp",params)
}
//重置密码
const resetPassword = function(params){
    return request.post("resetPassword",params)
}
//扫码登录
const qrLogin = function(params){
    return request.post("qrLogin/signIn",params)
}



//全部方法
const requestFun = {
    getQrCode,singIn,getCode,checkSmsCode,getAllTenant,signUp,businessSignUp,resetPassword,qrLogin
}

export default {
    ...requestFun,
    install:function(app){
        app.config.globalProperties.$url = "http://120.26.170.159:9001/api/1.0/auth/"
        app.config.globalProperties.$api = requestFun
    }
}