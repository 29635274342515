// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'

import routes from './routes.js'

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),

  routes,
  scrollBehavior(to) {
    if (!to.path.startsWith('/product/')) {
      return { top: 0 }
    }
  },
})

export default router
