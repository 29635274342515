import 'element-plus/dist/index.css'
import 'swiper/css';
import "swiper/css/navigation"
import "swiper/css/autoplay"
import "@/assets/icon/style.css"
import "@/assets/iconfont/iconfont.css"
import "@/assets/less/base.less"
import "@/assets/less/var.less"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import rules from '@/rules'
import api from '@/api'



const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(store)
    .use(router)
    .use(ElementPlus, {
        locale: zhCn,
    })
    .use(rules)
    .use(api)
    .mount('#app')
